<template>
    <div>
        <b-button variant="danger" v-b-modal.modal-1>Open modal</b-button>

        <b-card class="mt-3">
            <Modal
                :id="'modal-1'"
                :options="{'ok-only': true, 'ok-variant': 'primary'}"
                :okEvent="checkOkButton"
                :closeEvent="closeModal"
                :title="'BootstrapVue'"
            >
                <b-table striped hover :items="items"></b-table>
            </Modal>
        </b-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [
                {age: 40, first_name: 'Dickerson', last_name: 'Macdonald'},
                {age: 21, first_name: 'Larsen', last_name: 'Shaw'},
                {age: 89, first_name: 'Geneva', last_name: 'Wilson'},
                {age: 38, first_name: 'Jami', last_name: 'Carney'},
            ],
        };
    },
    methods: {
        checkOkButton($event) {
            $event.preventDefault();
        },
        closeModal() {
        },
    },
};
</script>

<style></style>
